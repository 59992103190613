import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby';
import './why-produceplan.less';
import { appUrl } from '../helpers/helpers'

import phoneicon from '../images/produceplan-iphoneicon.svg';
import targetPriceIcon from '../images/Target-price.svg';
import pipelineIcon from '../images/Pipeline.svg';
import integrationsIcon from '../images/Integrations.svg';
import videoThumb1 from '../images/video-thumb-1.png';
import videoThumb2 from '../images/video-thumb-2.png';
import videoThumb3 from '../images/video-thumb-3.png';
import videoThumb4 from '../images/video-thumb-4.png';
import blueArrowIcon from '../images/blue-arrow.png';
import bee1 from '../images/Asset_1Honeybee-1.svg';
import bee2 from '../images/Asset_2Honeybee-2.svg';
import pepperIcon from '../images/Asset 3pepper.svg';
import bananaIcon from '../images/Asset 4banana.svg';

const video1 = {
  title: 'Produceplan in just 4 minutes',
  url: 'https://vimeo.com/884960143',
  thumbnail: videoThumb1,
}
const video2 = {
  title: 'More with Produceplan: Real time freight for fresh produce',
  url: 'https://vimeo.com/produceplan/uberfreight-partnership',
  thumbnail: videoThumb2,
}
const video3 = {
  title: 'Product Feature Spotlight: Grouping mutiple LTL Produce Shipments',
  url: 'https://vimeo.com/891200310',
  thumbnail: videoThumb3,
}
const video4 = {
  title: 'Feature Spotlight: Order Profit Estimator & Digital Freight Quotes',
  url: 'https://vimeo.com/produceplan/featurespotlights',
  thumbnail: videoThumb4,
}

const signupUrl = `${appUrl}/signup?redirect=wizardGrower`;

const WhyProduceplanPage = () => (
  <Layout headerColor="#FFFFFF">
    <SEO title="Why Produceplan" />
    <div id="why-b">
      <div className="home-panel" id="hero-b">
        <div className="container">
          <div id="hero-b-left">
            <h2 style={{ fontWeight: 600, fontSize: 34 }}>
              FOCUS ON ACTIVITIES<br />
              THAT MATTER
            </h2>
            <img
              src={bee2}
              alt="Produceplan"
              height={80}
              className="hide-on-small-and-down"
              style={{ textAlign: 'center', position: 'absolute', top: 30, right: '20%' }}
            />
            <h6 style={{ fontWeight: 600}}>
              Produceplan is designed to:
            </h6>
            <ul className="pp-options-list">
              <li>Be your cloud-based control tower for all things fresh produce</li>
              <li>Offer plenty of highly customizable features</li>
              <li>Include live customer support</li>
              <li>Make scaling up as your team grows a breeze</li>
              <li>Consistently launch new integrations with leading software tools</li>
            </ul>
          </div>
          <div id="hero-b-right">
            <img
              src={phoneicon}
              alt="Produceplan"
            />
          </div>
          <img
            src={pepperIcon}
            alt="Produceplan"
            height={80}
            className="hide-on-small-and-down"
            style={{ textAlign: 'center', position: 'absolute', bottom: -40, left: '20%', zIndex: 950 }}
          />
        </div>
      </div>
    </div>    

    <div className="why-produceplan-wrapper">
      <div className="container" id="why-produceplan-1">
        <div className="row why-produceplan-row">
          <div className="col s12 m4 why-produceplan-item">
            <div className="why-produceplan-icon center-align">
              <img src={pipelineIcon} alt="target price" />
            </div>
            <h6 className="center-align" style={{ fontWeight: 600 }}>Unique data visibility</h6>
            <p>Our ERP combines data visibility and a <a href="https://app.produceplan.com/orders/new?source=produceplan.com/why-produceplan" target="_blank" style={{ fontWeight: 800, color: 'black', textDecoration: 'underline' }}>milestone-based</a> order management system that ensures your sales team focuses on the actions that drive profitable growth. With Produceplan, you always know what to do next and can be confident thar your service levels are industry leading.</p>
          </div>

          <div className="col s12 m4 why-produceplan-item">
            <div className="why-produceplan-icon center-align">
              <img src={integrationsIcon} alt="pipeline" />
            </div>
            <h6 className="center-align" style={{ fontWeight: 600 }}>Completely connected</h6>
            <p>With full <a href="https://app.produceplan.com/orders/new?source=produceplan.com/why-produceplan" target="_blank" style={{ fontWeight: 800, color: 'black', textDecoration: 'underline' }}>email integration</a>, easy import and export of data and an open API for connecting to EDI or other supply chain data streams, you always have the right data—exactly where you need—in a modern system. Add extensive features as you need specifically for sales, operations or accounting and you have the perfect tool to keep you growing.</p>
          </div>

          <div className="col s12 m4 why-produceplan-item">
            <div className="why-produceplan-icon center-align">
              <img src={targetPriceIcon} alt="integrations" />
            </div>
            <h6 className="center-align" style={{ fontWeight: 600 }}>Priced to suit competitive</h6>
            <p>With three pricing tiers and no hidden costs, you can manage your ERP budget reliably and accurately, while retaining the ability to scale up or down on short notice. Start using a best-in class product at a below competition price. <a href="https://app.produceplan.com/orders/new?source=produceplan.com/why-produceplan" target="_blank" style={{ fontWeight: 800, color: 'black', textDecoration: 'underline' }}>We are talking 20-30% improvements, consistently</a>.</p>
          </div>
        </div>
        <img
          src={bananaIcon}
          alt="Produceplan"
          height={80}
          className="hide-on-small-and-down"
          style={{ textAlign: 'center', position: 'absolute', bottom: -40, right: '20%', zIndex: 950 }}
        />
      </div>
    </div>

    <div className="for-suppliers-wrapper">
      <div className="container" id="why-produceplan-2">
        <div className="row">
          <div className="col s12">
            <h5 className="center-align" style={{ fontWeight: 600 }}>DESIGNED TO MAKE LIFE EASIER</h5>
            <p className="center-align">
              Applying our <strong style={{ fontWeight: 800 }}>milestone-based</strong> order management automation to ensure sales and operations <br />
              are always connected with finance and accounting.
            </p>
          </div>
        </div>
        <div className="row" style={{ marginBottom: 0 }}>
          <div className="col s12">
            <div className="pp-statuses center-align">
              <img src={blueArrowIcon} alt="blue arrow" className="blue-arrow" />
              <div className="pp-status pp-status-confirmed">Order Confirmed</div><br/>
              <div className="pp-status pp-status-loading">Truck Loading</div><br/>
              <div className="pp-status pp-status-passing">Passing Sent</div><br/>
              <div className="pp-status pp-status-delivered">Delivered</div><br/>
              <div className="pp-status pp-status-ready">Ready to Invoice</div><br/>
              <div className="pp-status pp-status-posted">Posted Invoices & Bills</div><br/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="why-produceplan-wrapper">
      <div className="container" id="why-produceplan-3">
        <div className="row">
            <img
              src={bee1}
              alt="Produceplan"
              height={64}
              className="hide-on-small-and-down"
              style={{ textAlign: 'center', position: 'absolute', top: 30, left: '20%' }}
            />
          <h5 className="center-align" style={{ fontWeight: 600 }}>
            CHECK OUT OUR VIDEOS
          </h5>
            <img
              src={bee2}
              alt="Produceplan"
              height={64}
              className="hide-on-small-and-down"
              style={{ textAlign: 'center', position: 'absolute', top: 30, right: '20%' }}
            />
          <p className="center-align">
            Growing crops, transporting product, creating inventory, and managing customer expectations is hard enough. <br/>
            Check out some videos below on how we make getting through the day-to-day grind <strong style={{ fontWeight: 800 }}>faster and more productive.</strong>
          </p>
          <div className="thumbnails-container">
            <div className="row">
              <div className="col s12 m6">
                <a className="thumbnail" href={video1.url} target="_blank">
                  <img src={video1.thumbnail} alt={video1.title} width={'100%'} />
                  <p>{video1.title}</p>
                </a>
              </div>
              <div className="col s12 m6">
                <a className="thumbnail" href={video2.url} target="_blank">
                  <img src={video2.thumbnail} alt={video2.title} width={'100%'} />
                  <p>{video2.title}</p>
                </a>
              </div>
              <div className="col s12 m6">
                <a className="thumbnail" href={video3.url} target="_blank">
                  <img src={video3.thumbnail} alt={video3.title} width={'100%'} />
                  <p>{video3.title}</p>
                </a>
              </div>
              <div className="col s12 m6">
                <a className="thumbnail" href={video4.url} target="_blank">
                  <img src={video4.thumbnail} alt={video4.title} width={'100%'} />
                  <p>{video4.title}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default WhyProduceplanPage
